import React, { Component } from 'react';
import { Nav, Navbar } from "react-bootstrap";
import Routes from "./Routes";
import Auth from "./modules/Auth";

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    const isAuthenticated = Auth.isUserAuthenticated(); 

    this.state = {
      isAuthenticated: isAuthenticated
    }
  }
  
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      <div className="App container">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">AWS Token Machine</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              {this.state.isAuthenticated
                ? <Nav.Item><Nav.Link href="/logout">Logout</Nav.Link></Nav.Item>
                : <Nav.Item><Nav.Link href="/login">Login</Nav.Link></Nav.Item>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps}/>
      </div>
    );
  }
}

export default App;
