import React, { Component } from "react";
import { Alert, Button, Modal, Card, ListGroup, CardColumns } from 'react-bootstrap';
import RoleAssume from "../modules/RoleAssume";
import Auth from "../modules/Auth";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as request from "request";

import "./TokenMachine.css";

class CredentialModal extends Component {
    render() {
        const credentials = ` export AWS_ACCESS_KEY_ID=${this.props.data.AccessKeyId}
 export AWS_SECRET_ACCESS_KEY=${this.props.data.SecretAccessKey}
 export AWS_SESSION_TOKEN=${this.props.data.SessionToken}`;
        return (
            <Modal show="true" onHide={this.props.onClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Temporary Credentials</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Here are your temporary credentials:</p>
                    <div className="credentialBox">
                        <pre>
                            {credentials}
                        </pre>
                    </div>
                    <CopyToClipboard text={credentials}>
                        <Button>
                            Copy
                        </Button>
                    </CopyToClipboard>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


class AccountRole extends Component {
    //{account_number: "750792802046", role_name: "GlobalAdmin", role_arn: "arn:aws:iam::750792802046:role/GlobalAdmin", provider: "arn:aws:iam::750792802046:saml-provider/cloud-services.its.uq.edu.au"}
    constructor(props) {
        super(props);

        this.callAssumeRole = this.callAssumeRole.bind(this);
        this.showCredentials = this.showCredentials.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
            credentials: {}
        }
    }

    handleClose() {
        this.setState({
            show: false,
            credentials: []
        });
    }

    showCredentials(err, data) {
        if (err) {
            alert("An error has occured when attempting to get credentials for this role.  Please contact cloudops@its.uq.edu.au if this persists as the Role may be set up incorrectly.");
            Auth.deauthenticateUser();
            window.location.replace("/");
        } else {
            this.setState({
                show: true,
                credentials: data.Credentials
            });
        }

    }

    callAssumeRole() {
        RoleAssume.assumeRole(this.props.data.provider, this.props.data.role_arn, this.showCredentials)
    }

    render() {
        const {
            show,
            credentials
        } = this.state;

        return (
            <div className='role'>
                <Button bsStyle="info" onClick={this.callAssumeRole}>
                    {this.props.data.role_name}
                </Button>
                {
                    show ? (
                        <CredentialModal data={credentials} onClose={this.handleClose}/>
                    ) : (
                        null
                    )
                }
            </div>
        )
    }
}

class Account extends Component {
    render() {
        return(
            <div className='account'>
                <Card border="info" bg="light">
                    <Card.Header>{this.props.data[0]['account_name'].substring(0, 41)}<br />
                    ({this.props.data[0]['account_number']})
                    </Card.Header>
                    <ListGroup>
                    {
                        this.props.data.map((item) => {
                            return (<ListGroup.Item><AccountRole data={item} /></ListGroup.Item>)
                        })
                    }
                    </ListGroup>
                </Card>                

            </div>
        );
    }
}


class AccountList extends Component {
    isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    render() {
        // Example: arn:aws:iam::714874568053:role/GlobalAdmin,arn:aws:iam::714874568053:saml-provider/cloud-services.its.uq.edu.au
        const accountAgg = {};
        for (let i = 0; i < this.props.data.length; i++) {
            const entry = this.props.data[i];
            const entry_members = entry.split(',');
            const role = entry_members[0];
            const provider = entry_members[1];
            const members = role.split(':');
            const account_number = members[4];
            const role_name = members[5].split('/')[1];
            let account_name = "";
            if (!this.isEmpty(this.props.mapping) && account_number in this.props.mapping) {
                account_name = JSON.parse(this.props.mapping[account_number])['name'];
            } 
            const accountObj = {
                account_number: account_number,
                account_name: account_name,
                role_name: role_name,
                role_arn: role,
                provider: provider
            }
            if (account_name in accountAgg) {
                accountAgg[account_name].push(accountObj);
            } else {
                accountAgg[account_name] = [];
                accountAgg[account_name].push(accountObj);
            }
        }

        return (
            <div id='accountlist'>
                <CardColumns>
                    {
                        Object.keys(accountAgg).sort().map((key, index) => {
                            return (<Account data={accountAgg[key]} />)
                        })
                    }
                </CardColumns>
            </div>
        );
    }
}


export default class TokenMachine extends Component {
    constructor(props) {
        super(props);
        this.metadataCallback = this.metadataCallback.bind(this);
        var roles = RoleAssume.convertPayloadToRoles();

        if (roles === null) {
            Auth.deauthenticateUser();
            window.location.replace("/");   
        }

        this.state = {
            permissions: roles,
            mapping: {}
        }
    }

    metadataCallback(error, response, body) {
        if (!error && response.statusCode === 200) {
            this.setState({
                mapping: JSON.parse(body)
            })
        }
    }

    componentDidMount() {
        this.timerID = setTimeout(
            () => {Auth.deauthenticateUser(); this.props.history.push("/login")},
            330000
        )

        const options = {
            url: 'https://7nk9loz342.execute-api.ap-southeast-2.amazonaws.com/prod/metadata/all',
            headers: {
                'x-api-key': 'aXZwfLoifz4EStJ8srava6LBOwCodsTw6VmxVUj6'
            }
        };
        request(options, this.metadataCallback);
    }

    render() {
        const {
            permissions,
            mapping
        } = this.state;

        if (permissions.length === 0) {
            return (
                <div id='nopermissions'>
                    <Alert bsStyle="info">
                        <p>It appears you have no permission to see anything.  Please contact <a href = "mailto: cloudops@its.uq.edu.au">cloudops@its.uq.edu.au</a> if you want to use this application.</p>
                    </Alert>
                </div>
            )
        } else {
            return(
                <div className='tokenmachineparent'>
                    <h2>Select a Role:</h2>
                    <AccountList data={permissions} mapping={mapping}/>
                </div>
            )
        }
    }
} 