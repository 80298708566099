import { Component } from "react";
import Auth from "../modules/Auth";
import QS from "query-string";

export default class LoginCallBack extends Component {
    async componentDidMount() {
        if (!Auth.isAttemptAuth()) return;

        // Parse URL params
        const parsed = QS.parse(this.props.location.search);
        const jwt = parsed.jwt;

        Auth.authenticateUser(jwt);
        Auth.finishAttemptAuth();
        await Auth.getSAMLCredentials(jwt, Auth.getIdentityId());
        this.props.userHasAuthenticated(true);
        this.props.history.push("/app");
    }

    render() {
        return null;
    }
}