import { Component } from "react";

import "./Home.css";

export default class Home extends Component {

  componentDidMount() {
    this.props.history.push('/app')
  }

  render() {
    return null;
  }
}