import config from "../config";
import APIGW from 'aws-api-gateway-client';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Auth {
  static authenticateUser(token) {
    const expiryDate = new Date(new Date().getTime() + (390000));
    const options = {
      expires: expiryDate,
      secure: true,
      sameSite: "strict"
    };
    cookies.set('jwt', token, options);
  }

  static isUserAuthenticated() {
    return cookies.get("jwt") !== undefined;
  }

  static deauthenticateUser() {
    cookies.remove('jwt');
    sessionStorage.removeItem('activelogin');
    localStorage.removeItem('identityId');
    localStorage.removeItem('accessKeyId');
    localStorage.removeItem('secretAccessKey');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('totlSAML');
  }

  static getToken() {
    return cookies.get("jwt");
  }

  static isUserAuthenticating() {
      return sessionStorage.getItem('activelogin') !== null;
  }

  static setAttemptAuth() {
    sessionStorage.setItem('activelogin', 'inProgress');
  }

  static finishAttemptAuth() {
    sessionStorage.removeItem('activelogin');
  }

  static isAttemptAuth() {
    return sessionStorage.getItem('activelogin') !== null;
  }

  static setIdentityId(id) {
    localStorage.setItem('identityId', id);
  }
  
  static getIdentityId() {
    return localStorage.getItem('identityId');
  }

  //Used in RelayState for 2-way JWT verification
  static randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  static calculateSAMLURI(identityId) {
    const spIdentity = encodeURIComponent(config.auth.spIdentity);
    const rString = this.randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    const RelayState = encodeURIComponent(identityId + '##' + rString);
    return config.auth.IDPURL + '?spentityid=' + spIdentity + "&RelayState=" + encodeURIComponent(RelayState);
  }

  static async getSAMLCredentials(jwt, identityId) {
    const clientConfig = {invokeUrl: config.app.invokeURL};
    const apigClient = APIGW.newClient(clientConfig);
    const pathTemplate = '/saml';
    const method = 'GET';
    const pathParams = {};
    const body = {};
    const additionalParams = {
      headers: {
        'Authorization': jwt,
        'UNIQUE_ID': identityId
      }
    };

    try {
      const result = await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body);
      localStorage.setItem('accessKeyId', result.data.credentials.credentials.Credentials.AccessKeyId);
      localStorage.setItem('secretAccessKey', result.data.credentials.credentials.Credentials.SecretAccessKey);
      localStorage.setItem('sessionToken', result.data.credentials.credentials.Credentials.SessionToken);
      localStorage.setItem('totlSAML', result.data.credentials.samlResponse);
    } catch (err) {
      // Not sure what to do here...
      // console.log(err);
      // console.log(err.response);
      console.log("Function failed...");
    }
  }
}
  
export default Auth;