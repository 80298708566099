import * as AWS from 'aws-sdk'
import * as xml2js from 'xml2js';
import * as xpath from 'xml2js-xpath';

class RoleAssume {

    static parseStringSync (str) {
        let result;
        new xml2js.Parser().parseString(str, (e, r) => { result = r });
        return result;
    }

    static convertPayloadToRoles() {
        const samlResponse = localStorage.getItem('totlSAML');
        if (samlResponse === null) {
            return null;
        }
        const samlResponseDecoded = new Buffer(samlResponse, 'base64').toString();

        // XML process
        const result = this.parseStringSync(samlResponseDecoded);
        const matches = xpath.find(result, '/samlp:Response/saml:Assertion/saml:AttributeStatement/saml:Attribute');

        let roleMatches = [];
        for (let i = 0; i < matches.length; i++) {
            if (matches[i]['$']['Name'] === "https://aws.amazon.com/SAML/Attributes/Role") {
                roleMatches = matches[i]['saml:AttributeValue'];
            }
        }

        let mappings = [];
        for (let i = 0; i < roleMatches.length; i++) {
            mappings.push(roleMatches[i]['_']);
        }
        return mappings;
    }

    static assumeRole(principalARN, roleARN, callback) {
        const samlPayload = localStorage.getItem('totlSAML');
        const sts = new AWS.STS();

        const params = {
            PrincipalArn: principalARN,
            RoleArn: roleARN,
            SAMLAssertion: samlPayload
        };

        sts.assumeRoleWithSAML(params, callback);
    }


    static trial() {
        const meow = localStorage.getItem('totlSAML');

        const sts = new AWS.STS();
        //arn:aws:iam::798203313403:role/GlobalAdmin,arn:aws:iam::798203313403:saml-provider/cloud-services.its.uq.edu.au

        const params = {
            PrincipalArn: 'arn:aws:iam::798203313403:saml-provider/cloud-services.its.uq.edu.au', /* required */
            RoleArn: 'arn:aws:iam::798203313403:role/GlobalAdmin', /* required */
            SAMLAssertion: meow
        };

        sts.assumeRoleWithSAML(params, function(err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else     console.log("Success");           // successful response
        });
    }
}

export default RoleAssume;