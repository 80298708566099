import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Error404 from "./containers/Error404";
import LoginCallBack from "./containers/LoginCallback";
import { PrivateRoute } from "./modules/PrivateRoute";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import TokenMachine from "./containers/TokenMachine";

import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) =>
  <Switch>
    <PrivateRoute path="/" exact component={Home} />
    <PrivateRoute path="/home" exact component={Home} />
    <AppliedRoute path="/callback" exact component={LoginCallBack} props={childProps}/>
    <Route path="/login" exact component={Login} />
    <AppliedRoute path="/logout" exact component={Logout} props={childProps}/>
    <PrivateRoute path="/app" exact component={TokenMachine} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={Error404} />
  </Switch>;