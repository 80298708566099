import React, { Component } from "react";
import Auth from "../modules/Auth";
import uuid from 'uuid';


export default class Login extends Component {
    componentDidMount() {
        // Redirect user if already authenticated
        let { from } = this.props.location.state || { from: { pathname: "/app" } };
        if (Auth.isUserAuthenticated()) {
            this.props.history.push(from);
        }

        var identityId = Auth.getIdentityId();

        // Generate SSO url and redirect
        if (identityId == null){
            // Create random unique token
            identityId = uuid.v4();
            Auth.setIdentityId(identityId);
        }
        const url = Auth.calculateSAMLURI(identityId);
        Auth.setAttemptAuth();
        window.location.href = url;
    }

    render() {
        return (
            <div>
                <h2>Redirecting to Login Provider...</h2>
            </div>
        );
    }
}