import React, { Component } from "react";
import Auth from "../modules/Auth";

export default class Logout extends Component {
  componentDidMount() {
    console.log("logged out");
    Auth.deauthenticateUser();
    this.props.userHasAuthenticated(false);
  }
  render() {
    return (
      <div className="Logout">
        <div className="lander">
          <h1>You have successfully logged out.</h1>
        </div>
      </div>
    );
  }
}